<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
		<div class="w-100 h-auto flex flex-column">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
				<el-form-item label="标题" prop="title">
				  <el-input v-model="form.title" placeholder="请输入标题" type="text" clearable />
				</el-form-item>
				<el-form-item label="作者" prop="anthor">
				  <el-input v-model="form.anthor" placeholder="请输入作者" type="text" clearable />
				</el-form-item>
                <el-form-item label="视频" prop="video">
					<div class="position-relative flex-all" style="width: 250px;" v-if="form.video">
						<video :src="form.video" style="width: 250px;" controls="controls"></video>
						<i class="el-icon-delete text-white position-absolute p-1" style="top: 0;right: 0;background-color: rgba(0,0,0,0.4);cursor: pointer;" @click.stop="delVideo()"></i>
					</div>
                	<el-upload
                	  class=""
                	  ref="upload"
                	  :limit="1"
					  action="#"
					  :on-change="uploadFile"
					  :on-remove="handleRemove"
                	  :on-success="uploadSuccess"
                	  :before-upload="beforeUpload"
					  :show-file-list='false'
					  :auto-upload="false"
                	  accept=".mp4"
					  v-show="!form.video"
                	  >
                	  <div class="border rounded flex-all mr-3" style="width: 100px;height: 100px;line-height: 100px;cursor: pointer;">
                		  <i class="el-icon-plus text-muted" style="font-size: 30px;"></i>
                	  </div>
                	</el-upload>
					<el-progress v-if="progressFlag" :percentage="loadProgress > 100 ? 100 : loadProgress" style="width: 200px;margin-top: 10px;"></el-progress>
                </el-form-item>
				<el-form-item label="设置时间" prop="time">
				  <el-date-picker
					 v-model="form.time"
					 value-format="yyyy-MM-dd"
					 type="date"
					 placeholder="选择日期">
				  </el-date-picker>
				</el-form-item>
				<el-form-item  label="发布状态">
				   <el-radio-group v-model="form.is_pub">
					<el-radio :label="1">使用</el-radio>
					<el-radio :label="2">停用</el-radio>
				   </el-radio-group>
				 </el-form-item>
				<el-form-item label="排序" prop="sort">
				  <el-input v-model="form.sort" placeholder="请输入排序" type="text" clearable />
				</el-form-item>
			</el-form>
			<div class="w-100 flex-all mt-2">
			  <el-button size="medium" @click="cancel">取 消</el-button>
			  <el-button type="primary" size="medium" @click="handleSubmit" style="margin-left: 30px;">确 定</el-button>
			</div>
		</div>
		</el-card>
	</div>
</template>

<script>
import GlobalTips from "@/components/GlobalTips";
import common from "@/common/mixins/common.js"
import Breadcrumb from '@/components/Breadcrumb';
import SparkMD5 from "spark-md5";
const chunkSize = 5 * 1024 * 1024;//定义分片的大小 暂定为10M
export default {
	inject:['app'],
	mixins:[common],
	components: {
		GlobalTips,
		Breadcrumb,
	},
	data() {
		return {
			preUrl: 'article',
			autoRequest: false,
			form:{
				title:'',
				anthor:'安徽省电力协会',
				type_id:7,
				cover:'',
				url:'',
				content:'',
				sort:100,
				is_pub:1,
				video:'',
				time:'',
			},
			rules:{
				title: [
				  { required: true, message: '请输入文章标题', trigger: 'blur' }
				],
				video: [
				  { required: true, message: '请选择视频', trigger: 'blur' }
				],
			},
			loadProgress: 0, // 动态显示进度条
			progressFlag: false, // 关闭进度条
			fileList:[],
			timer:null,
			finishtimer:null,
		}
	},
	mounted() {
		if(this.$route.query.article){
			 this.form = this.$route.query.article
		}
	},
	methods:{
		handleSubmit() {
		  this.$refs.form.validate(valid => {
		    if (valid) {
		      if (this.form.sort) {
		        this.form.sort = parseInt(this.form.sort)
		      }
			  this.form.images = this.images
		      if (this.form.id === undefined) {
		        this.axios.post('/manage/'+this.preUrl + '/add', this.form).then(res => {
		          if (res.status) {
		            this.$message.success('编辑成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      } else {
		        this.axios.put('/manage/'+this.preUrl + '/edit/' + this.form.id, this.form).then(res => {
		          if (res.status) {
		            this.$message.success('修改成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      }
		    }
		  })
		},
		cancel(){
			this.$router.go(-1);
		},
		delVideo(){
			this.form.video = ''
			this.loadProgress = 0
			this.progressFlag= false
			if(this.fileList.length > 0){
				this.fileList = []
				this.$refs.upload.clearFiles()
			}
		},
		/**
		 * 上传文件
		 */
		async uploadFile(Files) {
			//使用定时器来制作进度条
			this.loadProgress = 0
			this.progressFlag = true
			this.timer = window.setInterval(() => {
			  this.loadProgress++
					//这里之所以到72%就结束定时器，下面具体解释
			  if (this.loadProgress == 99) {
				window.clearInterval(this.timer)
				// 这里必须使用this.timer = null，否则清除定时器无效
				this.timer = null
			  }
			}, 100)
	
			var self = this
			//获取用户选择的文件
			const file = Files.raw
			this.currentFile = file
			//文件大小(大于100m再分片哦，否则直接走普通文件上传的逻辑就可以了，这里只实现分片上传逻辑)
			const fileSize = Files.size
			// 放入文件列表
			this.fileList = [{ "name": Files.name }]
			// 可以设置大于多少兆可以分片上传，否则走普通上传
			if (fileSize <= chunkSize) {
				console.log("上传的文件大于10m才能分片上传")
			}
			//计算当前选择文件需要的分片数量
			const chunkCount = Math.ceil(fileSize / chunkSize)
			console.log("文件大小：", (Files.size / 1024 / 1024) + "Mb", "分片数：", chunkCount)
			//获取文件md5
			const fileMd5 = await this.getFileMd5(file, chunkCount)
			console.log("文件md5：", fileMd5)
	
			console.log("向后端请求本次分片上传初始化")
	
			const initUploadParams = {
				"identifier": fileMd5, //文件的md5
				"filename": Files.name, //文件名
				"totalChunks": chunkCount, //分片的总数量
			}
			console.log(initUploadParams)
			// 调用后端检查文件上传情况接口
			this.axios.post("/manage/video/check", initUploadParams).then( async (res) => {
				// 获取后端返回的已上传分片数字的数组
				var uploaded = res.data.uploaded
				// 定义分片开始上传的序号
				// 由于是顺序上传，可以判断后端返回的分片数组的长度，为0则说明文件是第一次上传，分片开始序号从0开始
				// 如果分片数组的长度不为0，我们取最后一个序号作为开始序号
				var num = uploaded.length == 0 ? 0 : uploaded[uploaded.length - 1]
				// 当前为顺序上传方式，若要测试并发上传，请将103 行 await 修饰符删除即可
				// 循环调用上传
				for (var i = num; i < chunkCount; i++) {
					//分片开始位置
					let start = i * chunkSize
					//分片结束位置
					let end = Math.min(fileSize, start + chunkSize)
					//取文件指定范围内的byte，从而得到分片数据
					let _chunkFile = Files.raw.slice(start, end)
					console.log("开始上传第" + i + "个分片")
					let formdata = new FormData()
					formdata.append('identifier', fileMd5)
					formdata.append('filename', Files.name)
					formdata.append('totalChunks', chunkCount)
					formdata.append('chunkNumber', i)
					formdata.append('totalSize', fileSize)
					formdata.append('file', _chunkFile)
					console.log(formdata)
					// 通过await实现顺序上传
					await this.getMethods(formdata)
				}
				//文件上传完毕，请求后端合并文件并传入参数
				self.composeFile(fileMd5, Files.name, chunkCount)
			})
		},
	    /**
		 * 上传文件方法
		 * @param formdata 上传文件的参数
		 */
		getMethods(formdata) {
		  console.log(999)
		  console.log(formdata)
			return new Promise((resolve, reject) => {
				this.$axios({
				  method: 'post',
				  url: '/manage/video/upload',
				  headers: {
					'Content-Type': 'multipart/form-data'
				  },
				  data: formdata,
				}).then((res)=>{
				   resolve();
				})
			});
		},
		 /**
		  * 获取文件MD5
		  * @param file
		  * @returns {Promise<unknown>}
		  */
		getFileMd5(file, chunkCount) {
				return new Promise((resolve, reject) => {
					let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
					let chunks = chunkCount;
					let currentChunk = 0;
					let spark = new SparkMD5.ArrayBuffer();
					let fileReader = new FileReader();

					fileReader.onload = function (e) {
						spark.append(e.target.result);
						currentChunk++;
						if (currentChunk < chunks) {
							loadNext();
						} else {
							let md5 = spark.end();
							resolve(md5);
						}
					};
					fileReader.onerror = function (e) {
						reject(e);
					};
					function loadNext() {
						let start = currentChunk * chunkSize;
						let end = start + chunkSize;
						if (end > file.size) {
							end = file.size;
						}
						fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
					}
					loadNext();
				});
		},
		/**
		 * 请求后端合并文件
		 * @param fileMd5 文件md5
		 * @param fileName 文件名称
		 * @param count 文件分片总数
		 */
		composeFile(fileMd5, fileName, count) {
			console.log("开始请求后端合并文件")
			this.timer = null
			window.clearInterval(this.timer)
			var data = {
				"identifier": fileMd5, //文件的md5
				"filename": fileName, //文件名
				"totalChunks": count //分片的总数量
			}
			this.axios.post("/manage/video/compose", data).then((res) => {
				if (res.status) {
					this.loadProgress = 100
					this.form.video = res.data
					this.timer = null
					this.finishtimer = null
					window.clearInterval(this.timer)
					window.clearInterval(this.finishtimer)
				} else {
					this.timer = null
					this.finishtimer = null
					this.loadProgress = 0
					window.clearInterval(this.timer)
					window.clearInterval(this.finishtimer)
					this.$message.error(res.msg)
	                this.progressFlag = false
				}
			})
		},
		handleRemove(){
			this.timer = null
			this.finishtimer = null
			window.clearInterval(this.timer)
			window.clearInterval(this.finishtimer)
			this.fileList = []
			this.loadProgress = 0
			this.$refs.upload.clearFiles()
		},
		beforeUpload(file){
			let fileArr = file.name.split('.')
			let suffix = fileArr[fileArr.length - 1]
			//只能导入.xls和.xlsx文件
			if (!/(mp4)/i.test(suffix)) {
			this.$message('文件格式不正确')
			return false
			}
			return true
		},
		uploadSuccess(e){
			console.log(e)
			 if(e.status){
			   // this.form.url = e.data.url
			   // this.form.file_name = e.data.file_name
			 }
			 this.$refs.upload.clearFiles()
		},
		uploadError(e){
			console.log(e)
			this.$refs.upload.clearFiles()
		},
	},
}
</script>

<style>
</style>